<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <nav class="breadcrumb">
                    <ol class="breadcrumb-wrapper">
                        <li class="breadcrumb-item"><a href="javascript:">관리자</a></li>
                        <li class="breadcrumb-item">
                            <a href="javascript:">{{ divisions.title }}</a>
                        </li>
                    </ol>
                </nav>
                <h2 class="page-title">{{ divisions.title }} 상세</h2>
                <div class="page-title-bottom"></div>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <div class="board-form-container">
                    <div>
                        <div class="form-fields">
                            <div class="form-field">
                                <label class="form-label">객체 명</label>
                                <input v-model.trim="params.metaNm" type="text" class="form-input" readonly />
                            </div>
                            <!-- field:수행기간 입력 -->
                            <!--                            <div class="form-field">-->
                            <!--                                <label class="form-label">수행기간 입력</label>-->
                            <!--                                <input type="text" class="form-input" value="2021.03 - 2021.05" />-->
                            <!--                            </div>-->
                            <!-- field:내용 입력 -->
                            <div class="form-field">
                                <label class="form-label">내용 입력</label>
                                <!-- [!DEV] 에디터 영역, 높이 임의 설정 -->
                                <div class="editor-container swcc-editor-container" style="height: 957px">
                                    <div v-show="false" ref="pageEditor"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-row-container bottom-component">
                    <div class="bottom-side bottom-side-fluid">
                        <div class="btn-actions">
                            <button v-if="metaNo > 0" class="btn-action btn-text"><span class="text text-pink">삭제</span></button>
                            <button class="btn-action btn-text" @click="updateAwsMeta">
                                <span class="text">등록</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //page-bottom -->
    </main>
</template>
<style></style>

<script>
import {computed, onMounted, onUnmounted, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {getItem, isSuccess, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/common.utils';
import ApiService from '@/assets/js/api.service';
import {setPageEditor} from '@/assets/js/editor/editor.utils';
import {hideLoading, showLoading} from '@/assets/js/common.alert';
import {getAuthSession} from '@/assets/js/modules/auth/module';
import {validateParams} from '@/assets/js/common.validate';

export default {
    name: 'DataLakeEdit',
    components: {},
    setup: function () {
        showLoading();

        const route = useRoute();
        const router = useRouter();
        const pageEditor = ref(null);
        const session = computed(getAuthSession);

        const metaNo = computed(() => (route.params.metaNo ? parseInt(route.params.metaNo) : metaNo));

        const params = reactive({
            metaNo: 0,
            metaNm: '',
            metaContents: '',
        });

        const divisions = reactive({
            title: computed(() => {
                if (route.name.includes('DataLakeOrigin')) {
                    return '데이터 레이크';
                } else if (route.name.includes('DataLakePublic')) {
                    return '퍼블릭 데이터 마트';
                }
                return '';
            }),
        });

        let tryCount = 0;
        let instance = null;

        const setEditorInstance = editor => {
            instance = editor;
            editor.setData(params.metaContents);
            hideLoading();
        };

        const getAwsMeta = () => {
            if (metaNo.value > 0) {
                ApiService.get('/v1/aws/metas', metaNo.value)
                    .then(res => {
                        if (lengthCheck(res)) {
                            const item = getItem(res);
                            setParams(params, item);
                            setPageEditor(pageEditor.value, tryCount, setEditorInstance, '/v1/metas/uploads');
                        } else {
                            hideLoading();
                            router.push({name: `${route.name.replace('Edit', '')}`});
                        }
                    })
                    .catch(e => {
                        console.error(e);
                        hideLoading();
                    });
            }
        };

        const validateKeys = {
            metaContents: '내용을 입력해주세요.',
        };

        const updateAwsMeta = () => {
            params.metaContents = instance ? instance.getData() : params.metaContents;
            if (validateParams(validateKeys, params, false)) {
                if (confirm(`데이터 레이크 추가 정보를 등록하시겠습니까?`)) {
                    showLoading();

                    ApiService.update('/v1/aws/metas', params.metaNo, params)
                        .then(res => {
                            hideLoading();
                            if (isSuccess(res)) {
                                alert(`데이터 레이크 추가 정보가 등록되었습니다.`);
                            } else {
                                alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                            }
                        })
                        .catch(e => {
                            console.error(e);
                            hideLoading();
                            alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                        });
                }
            }
        };

        onMounted(() => {
            if (metaNo.value > 0 && session.value.manager) {
                getAwsMeta();
            } else {
                hideLoading();
                router.push({name: `${route.name.replace('Edit', '')}`});
            }
        });

        onUnmounted(() => {
            if (instance !== null) {
                instance.destroy();
            }
        });

        return {
            metaNo,
            divisions,
            params,
            pageEditor,
            updateAwsMeta,
            timestampToDateFormat,
        };
    },
};
</script>
